

  .marqueeTools-container {
    overflow-x: scroll; 
    overflow-y: hidden; 
    white-space: nowrap;
    box-sizing: border-box;
    position: relative;
    
  }
  

  .marqueeTools-container::-webkit-scrollbar {
    display: none; 
  }
  

  .marqueeTools-container {
    scrollbar-width: none; 
  }
  

  .marqueeTools-content {
    /* display: flex; */
    /* flex-direction: row; */
    /* width: 100%;
    height: 100%; */
    /* white-space: nowrap; */
    will-change: transform;
  }
  .marqueeTools-content img {
    
  
   
    border-radius: 10px;
    transition: transform 0.3s ease; 
   
    filter: drop-shadow(0 0 0.75rem rgba(23, 27, 56, 0.5));

  }
  
 
  .marqueeTools-content img:hover {
    transform: scale(1.05); 
  }
  
 
  .marqueeTools-content::before {
    content: attr(data-content);
    display: inline-block;
  }
  

  .marquee-right-to-left {
    animation: marquee-right-to-left 30s linear infinite;
    width: max-content;
  }
  

  .marquee-left-to-right {
    animation: marquee-left-to-right 30s linear infinite;
    width: max-content;
  }
  
 
  @keyframes marquee-right-to-left {
    100% {
      transform: translateX(-100%);
    }
    0% {
      transform: translateX(0);
    }
  }

  @keyframes marquee-left-to-right {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  