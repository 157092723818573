
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}



.neumorphic-icon {
  background: #e0e0e0; 
  border-radius: 50%;
  box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.2), -8px -8px 15px rgba(255, 255, 255, 0.9); /* Neumorphic shadow */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: background 0.3s, box-shadow 0.3s;
  padding: 10px;
  margin: 5px;
}

.neumorphic-icon:hover {
  background: #f5f5f5; 
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2), -4px -4px 10px rgba(255, 255, 255, 0.9);
}

.simple-icon {
  background: #0a0b0b33; 
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: background 0.3s, box-shadow 0.3s;
  padding: 10px;
  margin: 5px;
}

.simple-icon:hover {
  background: #171b38; 
  border:2px solid blue;
  filter: drop-shadow(0 0 0.75rem rgba(156, 203, 223, 0.5))
  /* box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1), -4px -4px 10px rgba(255, 255, 255, 0.9); */
}
.wave{
  



    --mask:
      radial-gradient(78.26px at 50% 105px,#000 99%,#0000 101%) calc(50% - 70px) 0/140px 100%,
      radial-gradient(78.26px at 50% -70px,#0000 99%,#000 101%) 50% 35px/140px 100% repeat-x;
    -webkit-mask: var(--mask);
            mask: var(--mask);
  }
  
.waveBottom{
  
 
    --mask:
      radial-gradient(88.54px at 50% calc(100% - 122.4px),#000 99%,#0000 101%) calc(50% - 96px) 0/192px 100%,
      radial-gradient(88.54px at 50% calc(100% + 74.4px),#0000 99%,#000 101%) 50% calc(100% - 48px)/192px 100% repeat-x;
    -webkit-mask: var(--mask);
            mask: var(--mask);
  
  
}

.waveTopBottom{
  
 
    --mask:
      radial-gradient(88.54px at 50% 122.4px,#000 99%,#0000 101%) calc(50% - 96px) 0/192px 51% repeat-x,
      radial-gradient(88.54px at 50% -74.4px,#0000 99%,#000 101%) 50% 48px/192px calc(51% - 48px) repeat-x,
      radial-gradient(88.54px at 50% calc(100% - 122.4px),#000 99%,#0000 101%) calc(50% - 96px) 100%/192px 51% repeat-x,
      radial-gradient(88.54px at 50% calc(100% + 74.4px),#0000 99%,#000 101%) 50% calc(100% - 48px)/192px calc(51% - 48px) repeat-x;
    -webkit-mask: var(--mask);
            mask: var(--mask);
  
}
.bg-gradientbluetoOffwhite {
  background: linear-gradient(to bottom, #171b38, #4A5B70, #99a0d338);
}

.bg-gradientOffwhitetoWhite {
  background: linear-gradient(to bottom, #99a0d338, #d4e3e6, #f6f7ff);
}



.bg-gradientWhitetoLightBlue {
  background: linear-gradient(to bottom, #f6f7ff, #d0e1f9, #5567ff);
}

.bg-gradientLightBluetoDarkBlue {
  background: linear-gradient(to bottom, #5567ff, #4a6ea0, #171b38);
}
.bg-gradientwhitetoOffWhite {
  background: linear-gradient(to bottom,#f6f7ff,#d4e3e6, #99a0d338);
}

.hide-scrollbar {
  overflow: scroll; 
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Custom scrollbar styling */
body {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #f0f0f0; /* Scrollbar thumb and track colors for Firefox */
}

/* Scrollbar for WebKit browsers */
body::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

body::-webkit-scrollbar-track {
  background: #f0f0f0; /* Background of the scrollbar track */
}

body::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded corners for the scrollbar */
  border: 2px solid #f0f0f0; /* Add some padding inside the thumb */
}

/* Optional hover effect on the scrollbar thumb */
body::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker shade on hover */
}
.waveLeftRight{

    --mask:
      radial-gradient(100.94px at 136.8px 50%,#000 99%,#0000 101%) 0 calc(50% - 96px)/51% 192px repeat-y,
      radial-gradient(100.94px at -88.8px 50%,#0000 99%,#000 101%) 48px 50%/calc(51% - 48px) 192px repeat-y,
      radial-gradient(100.94px at calc(100% - 136.8px) 50%,#000 99%,#0000 101%) 100% calc(50% - 96px)/51% 192px repeat-y,
      radial-gradient(100.94px at calc(100% + 88.8px) 50%,#0000 99%,#000 101%) calc(100% - 48px) 50%/calc(51% - 48px) 192px repeat-y;
    -webkit-mask: var(--mask);
            mask: var(--mask);
  
}