.hide-scrollbartest {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }
  
  .hide-scrollbartest::-webkit-scrollbar {
    display: none; 
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .scroll-containertest {
    display: flex;
    animation: scroll 30s linear infinite;
    /* width: max-content; */
  }
  
  