@keyframes slide-in-from-right {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slide-in-from-left {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slide-in-from-top {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes slide-in-from-bottom {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes zoom-in {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes bounce-in {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    20% {
      transform: scale(1.2);
      opacity: 1;
    }
    40% {
      transform: scale(0.9);
    }
    60% {
      transform: scale(1.05);
    }
    80% {
      transform: scale(0.98);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes bounce-down {
    0% {
      transform: translateY(-100%) scale(0.5);
      opacity: 0;
    }
    20% {
      transform: translateY(0) scale(1.2);
      opacity: 1;
    }
    40% {
      transform: translateY(-15%) scale(0.9);
    }
    60% {
      transform: translateY(5%) scale(1.05);
    }
    80% {
      transform: translateY(-3%) scale(0.98);
    }
    100% {
      transform: translateY(0) scale(1);
    }
  }
  .animate-slide-in-from-right {
    animation: slide-in-from-right 1.5s ease-in-out forwards;
  }
  
  .animate-slide-in-from-left {
    animation: slide-in-from-left 1.5s ease-in-out forwards;
  }
  
  .animate-slide-in-from-top {
    animation: slide-in-from-top 1.5s ease-in-out forwards;
  }
  
  .animate-slide-in-from-bottom {
    animation: slide-in-from-bottom 1.5s ease-in-out forwards;
  }
  
  .animate-fade-in {
    animation: fade-in 1.5s ease-in-out forwards;
  }
  
  .animate-zoom-in {
    animation: zoom-in 1.5s ease-in-out forwards;
  }
 
  
  .animate-bounce-in {
    animation: bounce-in 1.5s ease forwards;
  }

.animate-bounce-down {
  animation: bounce-down 1.5s ease forwards;
}
