.logo_font{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: rgb(51, 51, 51); /* Dark gray color */
    line-height: 25px;
  }

.nav_item{
    font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500; /* Medium weight */
  font-size: 18px; /* Font size */
  /* color: rgb(85, 103, 255); Blue color */
  line-height: 25px; /* Line height */
}

.small{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400; /* Regular weight */
    /* font-size: 16px;  */
    /* color: rgb(80, 85, 128);  */
    line-height: 25px; /* Line height */
    word-wrap: normal;
}

.poppins-bold {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600; /* Bold weight */
    /* font-size: 44px;  */
  
    line-height: 52px; /* Line height */
    word-wrap: normal;
  }
  